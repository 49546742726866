/**
 * Created by mwillard on 11/17/16.
 */
(function(app)
{
    app.controller('ModArticleCtrl', [
        '$scope','api', 'title', 'news', 'alerts', '$uibModalInstance','$rootScope',
        function($scope, api, title, news, alerts, $uibModalInstance, $rootScope)
        {
            $scope.dismiss = function()
            {
                $uibModalInstance.dismiss();
            };

            $scope.edit = news != null;

            if($scope.edit)
                $scope.news = news;
            else
                $scope.news = {};

            $scope.title = title;



            function new_article()
            {
                api
                    .addNews($scope.news)
                    .then(function(res)
                    {
                        alerts.success('Success', 'Article Added!');
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });

            }
            function edit_article()
            {
                api
                    .editNews($scope.news)
                    .then(function(res)
                    {
                        alerts.success('Success', 'Article Updated!');
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            }
            $scope.submit = function()
            {
                if($scope.edit)
                {
                    edit_article();
                }
                else
                    new_article();
                $rootScope.$broadcast("refresh-news");
                $scope.dismiss();
            };
            $scope.removeNews = function(id)
            {
                alerts.confirm('Are you Sure?', 'This will remove the news article, continue?',
                    function()
                    {
                        api
                            .deleteNews($scope.news.id)
                            .then(function(res)
                            {
                                alerts.success('Success', 'Article removed!');
                                $rootScope.$broadcast("refresh-news");
                            })
                            .catch(function(error)
                            {
                                alerts.error('Error', error);
                            });
                    })
            };

        }
    ])
})(window.bettermade_portal);