/**
 * Created by mwillard on 7/6/16.
 */
(function ()
{

    window.bettermade_portal = angular.module('bettermade_portal',
        [
            'ngRoute',
            'oitozero.ngSweetAlert',
            'CompiledTemplates',
            'smart-table',
            'ui.bootstrap',
            'ngFileUpload',
        ]).config([
        '$routeProvider', '$locationProvider', '$compileProvider',
        function ($routeProvider, $locationProvider, $compileProvider)
        {

            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|bmportal|chrome-extension):/);

            $routeProvider.when('/', {
                redirectTo: '/dashboard'
            });
            $routeProvider.when('/home', {
                redirectTo: '/dashboard'
            });

            $routeProvider.when('/dashboard', {
                controller: 'DashboardCtrl',
                templateUrl: '/templates/dashboard.html'
            });
            $routeProvider.when('/login', {
                controller: 'LoginCtrl',
                templateUrl: '/templates/login/login.html'
            });
            $routeProvider.when('/password-reset/:email&:token', {
                templateUrl: '/templates/login/password_reset.html',
                controller: 'PasswordResetCtrl'
            });
            $routeProvider.when('/users',{
                controller: 'UserCtrl',
                templateUrl: '/templates/users.html'
            });
            $routeProvider.when('/links',{
                controller: 'LinksCtrl',
                templateUrl: '/templates/links.html'
            });
            //---- Users
            $routeProvider.when('/password',
            {
                controller:'UpdatePasswordCtrl',
                templateUrl: '/templates/login/password_update.html'
            });
            //=======
            //----files
            $routeProvider.when('/files',
                {
                    controller: 'ModFilesCtrl',
                    templateUrl: '/templates/files/view.html'
                });
            $routeProvider.when('/files/:file_id',
            {
                controller: 'ModFilesCtrl',
                templateUrl: '/templates/files/view.html'
            });
            //=========

            //---- Links
            $routeProvider.when('/links',
            {
                controller: 'ModLinksCtrl',
                templateUrl: '/templates/links/view.html'
            });
            //=========

            //---- News
            $routeProvider.when('/news/:id',
            {
                controller: 'NewsCtrl',
                templateUrl: '/templates/news/all_news.html'
            });
            $routeProvider.when('/news',
                {
                    controller: 'NewsCtrl',
                    templateUrl: '/templates/news/all_news.html'
                });
            $routeProvider.when('/news/:news',
            {
                controller: 'ViewNewsCtrl',
                templateUrl: '/templates/news/view_news.html'
            });
            $routeProvider.when('/all-news',
            {
                controller: 'ModNewsCtrl',
                templateUrl: '/templates/news/mod_news.html'
            });
            //=========

            /*********/
            $routeProvider.when('/logout', {
                controller: [
                    '$rootScope','user',
                    function ($rootScope, user)
                    {
                        $rootScope.original_path = "/home";
                        user.revokeAuthentication();
                    }
                ],
                template: '<div class="container"><div class="col-xs-12 text-center"><h1>Please Wait</h1><p>We are revoking your authentication.</p></div></div>'
            });

            $routeProvider.otherwise({
                controller: [
                    '$scope', 'alerts', "$location", 'user',
                    function ($scope, alerts, $location, user)
                    {
                        alerts
                            .error('Page Not Found', 'Something went wrong!!! You got to this page, ' +
                                'but I do not know how serve it to you. Please contact your support ' +
                                'person.',
                                function ()
                                {
                                    if (user.authenticated)
                                    {
                                        $location.path('/home');
                                    } else
                                    {
                                        $scope.password_reset = false;
                                        $location.path('/login');
                                    }
                                });
                    }
                ],
                template: '<div></div>'
            });

            $locationProvider.html5Mode(true);
        }
    ])
})();