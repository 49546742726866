/**
 * Created by mwillard on 11/11/16.
 */
(function(app)
{
    app.controller('ModLinksCtrl',[
        '$scope','api','alerts','forms','$rootScope',
        function($scope, api, alerts, forms,$rootScope)
        {
            $scope.$parent.page_title = "All Links";
            $scope.tablestate = {};
            $scope.editLink = function(link)
            {
                var temp_link = {};
                angular.copy(link, temp_link);
                forms.editLink(temp_link);
            };
            $scope.addLink = forms.addLink;

            $scope.deleteLink = function(link)
            {
                alerts.confirm('Are you Sure?', 'This will remove the link, continue?',
                    function()
                    {
                        api
                            .deleteLink(link.id)
                            .then(function(res)
                            {
                                alerts.success('Success', 'Link removed!');
                                $scope.getLinks($scope.tablestate);
                            })
                            .catch(function(error)
                            {
                                alerts.error('Error', error);
                            });
                    });
            };

            $scope.getLinks = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;

                $scope.tablestate = tablestate;
                api
                    .searchAllLinks(tablestate)
                    .then(function(results)
                    {
                        $scope.isLoading = false;
                        $scope.all_links = [].concat(results.links);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        alerts.error('Error',results);
                    });

            };
            $rootScope.$on('refresh-links', function()
            {
                $scope.getLinks($scope.tablestate);
            });
        }
    ]);
})(window.bettermade_portal);