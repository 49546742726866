/**
 * Created by mwillard on 12/28/16.
 */
(function(app)
{
    app.controller('RecentFilesWidgetCtrl', [
        '$scope', '$http','$interval',
        function($scope, $http, $interval)
        {
            $scope.files = [];

            $scope.tick = 60000; // Check for new news every minute!
            $scope.last_id = 0;
            $scope.api = "/api/widget/files-widget?last_id="+$scope.last_id;

            $scope.prettyDate = function(content)
            {
                return moment(content).format('MMMM Do, h:mm a');
            };
            $scope.getFiles = function()
            {
                $scope.loading = true;
                $http.get($scope.api).then(
                    function(results)
                    {
                        var res = results.data.data.files;

                        if(res.length > 0)
                        {
                            $scope.files = res;
                            $scope.last_id = res[0].id;
                        }
                        $scope.loading = false;
                    },
                    function (error)
                    {
                        $scope.loading = false;
                    }
                )
            };

            var timer = $interval(function()
            {
                $scope.getFiles();
            },$scope.tick);

            $scope.getFiles();


        }
    ])
})(window.bettermade_portal);