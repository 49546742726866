/**
 * Created by mwillard on 11/11/16.
 */
(function(app)
{
    app.controller('ModNewsCtrl',[
        '$scope','api','alerts','$rootScope','forms',
        function($scope, api, alerts, $rootScope, forms)
        {
            $scope.$parent.page_title = "News";
            $scope.displayed = [];

            $scope.format_date = function(date)
            {
                return moment(date).format('MMMM Do YYYY, h:mm a');
            };
            $scope.deleteNews = function(news)
            {
                alerts.confirm('Are you Sure?', 'This remove the news article, continue?', function()
                {
                    api.deleteNews(news.id)
                        .then(function(res)
                        {
                            alerts.success('Success', 'News article removed!');
                            $scope.getNews($scope.tablestate);
                        })
                        .catch(function(error)
                        {
                            alerts.error('Error', error);
                        });
                });
            };
            $scope.addNews = function()
            {
                forms.addNews();
            };
            $scope.editNews = function(news)
            {
                var temp_news = {};
                angular.copy(news, temp_news);
                forms.editNews(temp_news);
            };
            $scope.getNews = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;

                $scope.tablestate = tablestate;

                api
                    .postSearchNews(tablestate)
                    .then(function(results)
                    {
                        console.log(results);
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.records);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        alerts.error('Error',results);
                    });

            };

            $rootScope.$on('refresh-news', function()
            {
                $scope.getNews($scope.tablestate);
            });

        }
    ])
})(window.bettermade_portal);