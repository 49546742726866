/**
 * Created by mwillard on 10/13/16.
 */
(function(app)
{
    app.controller('LoginCtrl', [
       '$scope', 'api', 'alerts', 'user',
        function($scope, api, alerts, user)
        {
            $scope.forgot_password = false;
            $scope.sending_request = false;
            $scope.login = function()
            {
                if(!$scope.forgot_password)
                {
                    user.authenticateUser($scope.user.username, $scope.user.password)
                        .then(function (authenticated)
                        {
                            if (!authenticated)
                            {
                                alerts.error('Login', user.auth_error_msg);
                            }
                        });
                }
                else
                {
                    $scope.sending_request = true;
                    api
                        .getPasswordResetRequest({email:$scope.user.username})
                        .then(function(response)
                        {
                            $scope.sending_request = false;
                            alerts.success('Sent Password Request', 'You have been emailed with instructions to reset your password.');
                        })
                        .catch(function(error)
                        {
                            $scope.sending_request = false;
                            alerts.error('Error', error);
                        });
                }

            };

        }
    ]);
})(window.bettermade_portal);