/**
 * Created by mwillard on 11/11/16.
 */
(
    function(app)
    {
        app.controller('PasswordResetCtrl', [
            '$scope', 'alerts', 'api', '$routeParams','$location',
            function($scope, alerts, api, $routeParams, $location)
            {
                $scope.sending_request = false;
                if($routeParams.email && $routeParams.token)
                    api
                        .getCheckPasswordResetToken({email:$routeParams.email, token:$routeParams.token})
                        .then(function()
                        {

                        })
                        .catch(function(error)
                        {
                            alerts.error('Error', error, function()
                            {
                                $location.path('/login');
                            }) ;
                        });

                $scope.submit = function()
                {
                    $scope.sending_request = true;
                    if($scope.user.password != $scope.user.confirm_password)
                    {
                        alerts.error('Password Mismatch!', 'The entered passwords do not match!');
                        $scope.sending_request = false;
                        return;
                    }

                    api
                        .postUpdatePassword({email:$routeParams.email, password:$scope.user.password, token:$routeParams.token})
                        .then(function()
                        {
                            alerts.success('Password Updated!', 'Your password has been updated!');
                            $scope.sending_request = false;
                            $location.path('/login');
                        })
                        .catch(function(error)
                        {
                            $scope.sending_request = false;
                            alerts.error('Error', error);
                        })
                }

            }
        ])
    }
)(window.bettermade_portal);