/**
 * Created by mwillard on 11/11/16.
 */
(function(app)
{
    app.controller('NewsCtrl',[
        '$scope','api','alerts','$routeParams',
        function($scope, api, alerts,$routeParams)
        {

            $scope.news_id = null;

            if("id" in $routeParams)
            {
                console.log($routeParams);
                $scope.news_id = Number($routeParams.id);
            }

            $scope.$parent.page_title = "News";
            $scope.displayed = [];

            $scope.format_date = function(date)
            {
                return moment(date).format('MMMM Do YYYY, h:mm a');
            };
            $scope.getNews = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;

                $scope.tablestate = tablestate;

                if($scope.news_id != null)
                {
                    tablestate.search.predicateObject = {id:$scope.news_id};
                }

                api
                    .postSearchNews(tablestate)
                    .then(function(results)
                    {
                        console.log(results);
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.records);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        alerts.error('Error',results);
                    });

            };

        }
    ])
})(window.bettermade_portal);