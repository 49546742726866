'use strict';


(function (app) {

    app.factory('forms', [
        '$uibModal', 'api', 'alerts', 'user',
        function ($uibModal, api, alerts, user) {
            var forms = {};

            forms.addUser = function (title, callback) {
                api.getUserPermissions()
                    .then(function (results) {
                        var types =
                        {
                            groups: results.groups,
                            types: results.types
                        };
                        var modalInstance = $uibModal.open({
                            templateUrl: "/templates/formPopUps/users/add.html",
                            size: 'lg',
                            windowTemplateUrl: "/templates/modalTemplate.html",
                            controller: 'ModUserCtrl',
                            resolve: {
                                title: function () {
                                    return title;
                                },
                                user: function () {
                                    return null;
                                },
                                callback: function () {
                                    return callback;
                                },
                                groups: function () {
                                    return types;
                                }
                            }
                        });
                    })
                    .catch(function (error) {
                        alerts.error('Error', error);
                    });

            };
            forms.editUser = function (user_data, callback) {
                api.getUserPermissions()
                    .then(function (results) {
                        var types =
                        {
                            groups: results.groups,
                            types: results.types
                        };
                        var modalInstance = $uibModal.open({
                            templateUrl: "/templates/formPopUps/users/edit.html",
                            size: 'lg',
                            windowTemplateUrl: "/templates/modalTemplate.html",
                            controller: 'ModUserCtrl',
                            resolve: {
                                title: function () {
                                    return 'Edit User';
                                },
                                user: function () {
                                    return user_data;
                                },
                                callback: function () {
                                    return callback;
                                },
                                groups: function () {
                                    return types;
                                }


                            }
                        });
                    })
                    .catch(function (error) {
                        alerts.error('Error', error);
                    });

            };
            forms.addFile = function (callback, user_upload) {
                api.getUserPermissions()
                    .then(function (results) {
                        var types =
                        {
                            groups: results.groups,
                            types: results.types
                        };
                        var modalInstance = $uibModal.open({
                            templateUrl: "/templates/formPopUps/files/mod_file.html",
                            size: 'lg',
                            windowTemplateUrl: "/templates/modalTemplate.html",
                            controller: 'ModFileCtrl',
                            resolve: {
                                title: function () {
                                    return 'Add File';
                                },
                                file: function () {
                                    return null;
                                },
                                callback: function () {
                                    return callback;
                                },
                                groups: function () {
                                    return types;
                                },
                                user_upload: function() {
                                    return user_upload != null;
                                }
                            }
                        });
                    }).catch(function (error) {
                    alerts.error('Error', error);
                });

            };
            forms.editFile = function (file, callback) {
                api.getUserPermissions()
                    .then(function (results) {
                        var types =
                        {
                            groups: results.groups,
                            types: results.types
                        };
                        var modalInstance = $uibModal.open({
                            templateUrl: "/templates/formPopUps/files/mod_edit_file_admin.html",
                            size: 'lg',
                            windowTemplateUrl: "/templates/modalTemplate.html",
                            controller: 'ModFileCtrl',
                            resolve: {
                                title: function () {
                                    return 'Edit File';
                                },
                                file: function () {
                                    return file;
                                },
                                callback: function () {
                                    return callback;
                                },
                                groups: function () {
                                    return types;
                                },
                                user_upload: function() {
                                    return null;
                                }
                            }
                        });
                    }).catch(function (error) {
                    alerts.error('Error', error);
                });
            };
            forms.addLink = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/links/add.html",
                    size: 'md',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModLinkCtrl',
                    resolve: {
                        title: function () {
                            return 'Add Link';
                        },
                        link: function () {
                            return null;
                        }
                    }
                });
            };
            forms.editLink = function (link) {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/links/edit.html",
                    size: 'md',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModLinkCtrl',
                    resolve: {
                        title: function () {
                            return 'Edit Link';
                        },
                        link: function () {
                            return link;
                        }
                    }
                });
            };
            forms.addNews = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/news/add.html",
                    size: 'lg',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModArticleCtrl',
                    resolve: {
                        title: function () {
                            return 'Add Article';
                        },
                        news: function () {
                            return null;
                        }
                    }
                });
            };
            forms.editNews = function (news) {
                var modalInstance = $uibModal.open({
                    templateUrl: "/templates/formPopUps/news/edit.html",
                    size: 'lg',
                    windowTemplateUrl: "/templates/modalTemplate.html",
                    controller: 'ModArticleCtrl',
                    resolve: {
                        title: function () {
                            return 'Edit Article';
                        },
                        news: function () {
                            return news;
                        }
                    }
                });
            };

            return forms;
        }
    ]);


})(window.bettermade_portal);