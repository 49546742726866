/**
 * Created by mwillard on 12/28/16.
 */
(function (app) {
    app.directive('widgetLoader', function () {
        return {
            restrict: 'A',
            scope: {
                "type":"@"
            },
            templateUrl:'/templates/widgets/loader.html'
        };
    });
})(window.bettermade_portal);