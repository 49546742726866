/**
 * Created by mwillard on 11/14/16.
 */
(function (app) {
    app.controller('UpdatePasswordCtrl', [
        '$scope', 'api','alerts',
        function($scope, api, alerts)
        {
            $scope.$parent.page_title = "Update Password";
            $scope.user = {};

            $scope.submit = function()
            {
                if($scope.user.new_password != $scope.user.confirm) {
                    alerts.error('Error', 'Passwords do not match!');
                    return;
                }
                api.postUpdateUserPassword({data:$scope.user})
                    .then(function(res)
                    {
                        alerts.success('Success', 'Password updated!');
                        $scope.user = {};
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };

        }
    ])
})(window.bettermade_portal);