/**
 * Created by mwillard on 10/14/16.
 */

(function (app) {
    app.directive('sidebar', function () {
        return {
            restrict: 'A',
            scope: {

            },
            link: function (scope, elem, attrs) {
                $(elem).click(function (e) {
                    if($(document).width() <= 950) {
                        e.preventDefault();
                        $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');

                    }
                });
            }
        };
    });
})(window.bettermade_portal);