/**
 * Created by mwillard on 11/9/16.
 */
(function(app)
{
    app.service('files',[
        'api','alerts',
        function(api, alerts) {

            var self = this;

            self.files = {};
            self.links = [];

            re_init_navbar();

            self.checkFiles = function()
            {
                checkForAll();
                checkForPending();
                checkAllLinks();

            };

            function checkForPending()
            {
                api.getPendingFiles()
                    .then(function (res) {

                        for(var i in res.files)
                        {
                            var file = res.files[i];
                            file = format_and_hours_ago(file);
                            res.files[i] = file;
                        }

                        self.files.pending = res.files;

                    })
                    .catch(function (error) {
                        alerts.error('Error', error);
                    });
            }

            function re_init_navbar()
            {
                $('.navigation-menu li.has-submenu a[href="#"]').on('click', function (e) {
                    console.log("Test!");
                    if ($(window).width() < 992) {
                        e.preventDefault();
                        $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
                    }
                });
            }

            function format_and_hours_ago(file)
            {
                file.hours_ago = moment().diff(moment(file.updated_at),"hours");
                file.minutes_ago = moment().diff(moment(file.updated_at), "minutes");
                return file;
            }

            function checkForAll()
            {
                api.getAllFiles()
                    .then(function(res)
                    {

                        for(var i in res.files)
                        {
                            var file = res.files[i];

                            if(file.approved == 0)
                                continue;

                            if(self.files[file.type_code] == null)
                                self.files[file.type_code] = [];

                            var match = false;
                            for(var x in self.files[file.type_code])
                            {
                                if(self.files[file.type_code][x].id == file.id)
                                    match = true;
                            }
                            if (!match)
                                self.files[file.type_code].push(file);
                        }

                    })
                    .catch(function(error)
                    {
                        console.log('ERROR:' + error);
                    })
            };

            function checkAllLinks()
            {
                api.getAllLinks()
                    .then(function(res)
                    {
                        self.files.LINKS = res.links
                    })
                    .catch(function(error)
                    {
                        console.log('ERROR:: ' . error);
                    });
            }


        }]);
})(window.bettermade_portal);