/**
 * Created by mwillard on 11/9/16.
 */
(function(app)
{
    app.controller('ModFilesCtrl',[
        '$scope', 'api', '$routeParams', 'forms', 'alerts','$rootScope','$location',
        function($scope, api, $routeParams, forms, alerts, $rootScope, $location)
        {
            $scope.$parent.page_title = "All Files";
            $scope.tablestate = {};
            $scope.editFile = function(file_id)
            {
                api.getFileById(file_id)
                    .then(function(res)
                    {
                        forms.editFile(res.file, function($uibmodal, file)
                        {
                            //Save file
                        });
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };
            $scope.addFile = function()
            {
                forms.addFile(function($uibmodal, file)
                {
                   //save file
                });
            };
            $scope.downloadFile = function(file)
            {
                var path;

                switch(file.upload_type)
                {
                    case "LINK":
                        path = file.href;
                        break;
                    case "UPLOAD_FILE":
                        path = $location.$$absUrl.toString().replace($location.$$path, "/" + file.href);
                        break;
                    case "LOCAL_FILE":
                        path = ('bmportal://' + file.id);
                        break;
                }
                window.open(path);
            };
            $scope.deleteFile = function(file)
            {
                alerts.confirm('Are you Sure?', 'This will remove the file, continue?',function()
                {
                    api.removeFile(file.id)
                        .then(function(res)
                        {
                            alerts.success('Success', 'File Removed!');
                            $scope.getFiles($scope.tablestate);

                        })
                        .catch(function (error) {
                            alerts.error('Error', 'Unable to remove file! Please try again!');
                        });
                });

            };
            $scope.approveFile = function(file)
            {
                alerts.confirm('Are you Sure?', 'This will mark the file as approved! Are you sure you want to continue?',
                    function()
                    {
                        api.approveFile(file.id)
                            .then(function(res)
                            {
                                $scope.getFiles($scope.tablestate);
                            })
                            .catch(function(error)
                            {
                                alerts.error('Error', error);
                            });
                    },
                    function()
                    {

                    }
                )
            };
            $rootScope.$on('refresh-files', function()
            {
                $scope.$parent.files.checkFiles();
                $scope.getFiles($scope.tablestate);
            });
            $scope.getFiles = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;

                $scope.tablestate = tablestate;
                api
                    .searchAllFiles(tablestate)
                    .then(function(results)
                    {

                        $scope.isLoading = false;
                        $scope.all_files = [].concat(results.files);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        alerts.error('Error',results);
                    });

            };

            if($routeParams.file_id != null)
            {
                $scope.editFile($routeParams.file_id);
            }
        }
    ])
})(window.bettermade_portal);