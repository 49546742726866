/**
 * Created by mwillard on 11/11/16.
 */
(function(app)
{
    app.controller('ModLinkCtrl', [
        '$scope','api', 'title', 'link', 'alerts', '$uibModalInstance','$rootScope',
        function($scope, api, title, link, alerts, $uibModalInstance, $rootScope)
        {
            $scope.dismiss = function()
            {
                $uibModalInstance.dismiss();
            };

            $scope.edit = link != null;

            if($scope.edit)
                $scope.link = link;
            else
                $scope.link = {};

            $scope.title = title;



            function new_link()
            {
                api
                    .addLink($scope.link)
                    .then(function(res)
                    {
                        alerts.success('Success', 'Link Added!');
                    })
                    .catch(function(error)
                    {
                       alerts.error('Error', error);
                    });

            }
            function edit_link()
            {
                api
                    .editLink($scope.link)
                    .then(function(res)
                    {
                        alerts.success('Success', 'Link Updated!');
                    })
                    .catch(function(error)
                    {
                       alerts.error('Error', error);
                    });
            }
            $scope.submit = function()
            {
                if($scope.edit)
                {
                    edit_link();
                }
                else
                    new_link();
                $rootScope.$broadcast("refresh-links");
                $scope.dismiss();
            };
            $scope.removeLink = function(id)
            {
                alerts.confirm('Are you Sure?', 'This will remove the link, continue?',
                    function()
                    {
                        api
                            .removeLink($scope.link.id)
                            .then(function(res)
                            {
                                alerts.success('Success', 'Link removed!');
                                $rootScope.$broadcast("refresh-links");
                            })
                            .catch(function(error)
                            {
                                alerts.error('Error', error);
                            });
                    })
            };

        }
    ])
})(window.bettermade_portal);