'use strict';

(function (app)
{
    app.controller('AppCtrl', [
        '$scope', '$location', 'api', 'alerts', '$rootScope', 'user', '$interval','files','forms',
        function ($scope, $location, api, alerts, $rootScope, user, $interval, files, forms)
        {

            var file_timer;

            $scope.page_title = "";

            $scope.login_out_sites = ['(\/login\/)','(\/password-reset)'];
            $scope.login_out_sites_reg = new RegExp($scope.login_out_sites.join('|'));
            $scope.non_auth_sites = ['(\/password-reset\/)','(\/test)'];
            $scope.non_auth_sites_reg = new RegExp($scope.non_auth_sites.join('|'));

            $scope.user = user;
            $scope.files = files;
            $scope.menus = [
                {
                    name: 'Home',
                    href: '/home',
                    active: false,
                    icon: 'home',
                    user_rights:[],
                    items: []
                },
                {
                    name: 'Programs',
                    href: '#',
                    code: 'PROG',
                    user_rights:[],
                    active: false,
                    icon: 'application',

                    items: []
                },
                {
                    name: 'Finance',
                    href: '#',
                    code: 'FIN',
                    active: false,
                    user_rights:[user.groups.financial,user.groups.IT],
                    icon: 'bank',
                    items: [

                    ]
                },
                {
                    name: 'Production',
                    href: '#',
                    code: 'PROD',
                    user_rights:[user.groups.IT],
                    active: false,
                    icon: 'worker',
                    items: [
                    ]
                },
                {
                    name: 'Sales',
                    href: '#',
                    code: 'SALES',
                    user_rights:[user.groups.IT],
                    active: false,
                    icon: 'chart-line',
                    items: [
                    ]
                },
                {
                    name: 'Warehouse',
                    href: '#',
                    code: 'WAREHOUSE',
                    user_rights:[user.groups.IT],
                    active:false,
                    icon:'worker',
                    items: [
                    ]
                },
                {
                    name: 'HR/Payroll',
                    href: '#',
                    user_rights:[user.groups.human_resources,user.groups.IT],
                    active:false,
                    icon:'briefcase',
                    code: 'HR',
                    items: [
                    ]
                },
                {
                    name: 'SQF',
                    href: '#',
                    active:false,
                    icon:'food-apple',
                    code: 'SQF',
                    user_rights:[user.groups.IT],
                    items: [
                    ]
                },
                {
                    name: 'News',
                    href: '/news',
                    active:false,
                    user_rights:[],
                    icon:'newspaper',
                    items: [
                    ]
                },
                {
                    name: 'Links',
                    href: '#',
                    active:false,
                    user_rights:[],
                    code:'LINKS',
                    icon:'paperclip',
                    items: [
                    ]
                }


            ];

            $scope.side_menus =
                [
                    {
                        name: 'Add User',
                        icon: 'user',
                        user_rights:[user.groups.IT, user.groups.SUPER],
                        href: '/users'
                    },
                    {
                        name: 'All Files',
                        icon: 'file',
                        user_rights:[user.groups.IT, user.groups.SUPER],
                        href: '/files'
                    },
                    {
                        name: 'All Links',
                        icon: 'link',
                        user_rights:[user.groups.IT, user.groups.SUPER],
                        href: '/links'
                    },
                    {
                        name: 'All News',
                        icon: 'layout',
                        user_rights:[user.groups.IT, user.groups.SUPER],
                        href: '/all-news'
                    },
                    {
                        name: 'Change Password',
                        icon: 'lock',
                        user_rights:[],
                        href: '/password'
                    }
                ];
            $scope.alertUser = function()
            {
                window.open("/get-viewer");
                alerts.success("Notice!!", "Make sure to run this application when downloaded! Files will not open otherwise!");
            };

            $scope.addFile = function()
            {
                forms.addFile(function($uibmodal,file)
                {

                },true);
            };

            $scope.getMenuLinks = function (code)
            {
                if($scope.files != null && $scope.files.files[code] != null)
                    return $scope.files.files[code];
                return [];
            };
            $scope.getLinkType = function (link) {
                if(null != link.upload_type)
                {
                    switch(link.upload_type)
                    {
                        case "LINK":
                        case "UPLOAD_FILE":
                            return link.href;
                        case "LOCAL_FILE":
                            return "bmportal://" + link.id;
                    }
                }
                return link.href;
            };
            $scope.$on('$locationChangeSuccess', function (event, location)
            {
                $scope.use_padding = true;

                if (user.authenticated && ($rootScope.original_path == '/login' || $rootScope.original_path == '/logout' || $scope.non_auth_sites_reg.test($location.path())))
                {
                    $location.path('/home');
                }


            });

            $scope.$on('$locationChangeStart', function(e, next, current)
            {

                if(user.authenticated && $scope.login_out_sites_reg.test(next))
                {
                    $location.path('/home');
                }
                for (var i in $scope.menus)
                {
                    var item = $scope.menus[i];

                    var reg = new RegExp(item.href + '$');

                    if (reg.test(next))
                    {
                        user.fetchCurrentUser().then(function(results)
                        {
                            if(!user.hasGroup(item.user_rights))
                            {
                                alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                                $location.path('/home');
                            }
                        }).catch(function () {
                            alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                            $location.path('/home');
                        })

                    }
                    else
                    {
                        item.active = false;

                        if (item.hasOwnProperty('items'))
                        {
                            for (var ii in item.items)
                            {
                                var item2 = item.items[ii];
                                var reg2 = new RegExp(item2.href + '$');

                                if (reg2.test(next))
                                {
                                    user.fetchCurrentUser().then(function(results)
                                    {
                                        if(!user.hasPermission(item2.user_rights))
                                        {
                                            alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                                            $location.path('/home');
                                        }
                                    }).catch(function () {
                                        alerts.error('Lacking Rights!', 'You do not have the rights to access this page!');
                                        $location.path('/home');
                                    })
                                }
                            }
                        }
                    }
                }
            });

            $rootScope.original_path = $location.path();//'/home';

            $scope.$watch('user.authenticated', function (authenticated)
            {
                if (authenticated)
                {
                    if ($rootScope.original_path == '/login' || $rootScope.original_path == '/logout' || $scope.non_auth_sites_reg.test($location.path()))
                    {
                        $rootScope.original_path = '/home';
                    }

                    $location.path($rootScope.original_path).replace();

                    //Handle Notifications
                    $scope.files = files;
                    $scope.files.checkFiles();
                    file_timer = $interval(function()
                    {
                        $scope.files.checkFiles();
                    },10000);
                }
                else
                {

                    if ($scope.non_auth_sites_reg.test($location.path()))
                        return;

                    var cpath = $location.path();

                    if (cpath != '/login' && cpath != '/logout')
                    {
                        $rootScope.original_path = cpath;
                    }
                    $interval.cancel(file_timer);
                    file_timer = null;
                    $location.path('/login').replace();
                }
            });

            if (!$scope.non_auth_sites_reg.test($location.path()))
            {
                $location.path('/login').replace();
            }

        }
    ]);
})(window.bettermade_portal);
