/**
 * Created by mwillard on 11/16/16.
 */
(function(app)
{
    app.controller('UserUploadFile', [
        '$scope', 'api', 'forms',
        function($scope, api, forms)
        {
            forms.addFile(function($uimodal, file)
            {

            });
        }
    ])
})(window.bettermade_portal);