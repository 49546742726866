/**
 * Created by mwillard on 10/13/16.
 */
(function(app)
{
    app.controller('DashboardCtrl', [
        '$scope','api', 'alerts',
        function($scope, api, alerts)
        {
            $scope.$parent.page_title = "Dashboard";
        }
    ])
})(window.bettermade_portal);