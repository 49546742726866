(function (app)
{
    app.service('user', [
        'api',
        function (api)
        {
            var self = this;

            self.permissions = {
                admin: 1,
                basic: 2
            };
            self.groups =
            {
                human_resources:1,
                IT:2,
                financial:3,
                super_user:4
            };

            self.authenticated = false;
            self.auth_error_msg = "";
            self.user = false;
            self.user_permissions = [];

            getIsAuthenticated();

            self.authenticateUser = function (email, password)
            {
                return api
                    .sendAuthentication({
                        email: email,
                        password: password
                    })
                    .then(function (response)
                    {
                        if (response.authenticated)
                        {
                            self.authenticated = true;
                            self.auth_error_msg = "";
                            self.getCurrentUser();
                            return true;
                        }
                        else
                        {
                            self.authenticated = false;
                            self.auth_error_msg = response.error;
                            return false;
                        }
                    });
            };

            self.revokeAuthentication = function ()
            {
                return api.revokeAuthentication()
                    .then(function ()
                    {
                        self.user = false;
                        self.authenticated = false;
                    });
            };

            self.hasGroup = function(group)
            {
                if(group.length == 0)
                    return true;
                return group.indexOf(self.user.user_group_id) !== -1;
            };

            self.hasPermission = function (permission)
            {
                return self.user.user_type_id == permission;
            };

            self.fetchCurrentUser = function()
            {
                return api
                    .getCurrentUser()
                    .then(function (result)
                    {
                        self.user = result.user;
                        self.user_permissions = [];

                        // for (var i = 0; i < result.user.user_type.length; i++)
                        // {
                        //     self.user_permissions.push(result.user.user_type[i].type.user_code)
                        // }
                    });
            };

            self.getCurrentUser = function()
            {
                api
                    .getCurrentUser()
                    .then(function (result)
                    {
                        self.user = result.user;
                        self.user_permissions = [];

                        // for (var i = 0; i < result.user.user_type.length; i++)
                        // {
                        //     self.user_permissions.push(result.user.user_type[i].type.user_code)
                        // }
                    });
            };

            function getIsAuthenticated()
            {
                api.isAuthenticated()
                    .then(function (result)
                    {
                        if (result.authenticated)
                        {
                            self.authenticated = true;
                            self.getCurrentUser();
                        }
                        else
                        {
                            self.authenticated = false;
                        }
                    });
            }
        }
    ]);
})(window.bettermade_portal);
