/**
 * Created by mwillard on 12/28/16.
 */
(function(app)
{
    app.controller('NewsWidgetCtrl', [
        '$scope', '$http','$interval',
        function($scope, $http, $interval)
        {
            $scope.news = [];

            $scope.tick = 60000; // Check for new news every minute!
            $scope.last_id = 0;
            $scope.api = "/api/widget/news-widget?last_id="+$scope.last_id;

            $scope.shortenContent = function(content)
            {
                if(content.length > 150)
                    return content.substring(0,150) + "...";
                return content;

            };
            $scope.prettyDate = function(content)
            {
                return moment(content).format('MM/D, h:mm a');
            };
            $scope.getNews = function()
            {
                $scope.loading = true;
                $http.get($scope.api).then(
                    function(results)
                    {
                        var res = results.data.data.news;

                        if(res.length > 0)
                        {
                            $scope.news = res;
                            $scope.last_id = res[0].id;
                        }
                        $scope.loading = false;
                    },
                    function (error)
                    {
                        console.log(error);
                        $scope.loading = false;
                    }
                )
            };

            var timer = $interval(function()
            {
                $scope.getNews();
            },$scope.tick);

            $scope.getNews();


        }
    ])
})(window.bettermade_portal);