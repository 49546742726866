/**
 * Created by mwillard on 11/10/16.
 */
(function(app)
{
    app.controller('ModFileCtrl',[
        '$scope', '$uibModalInstance', 'alerts', 'title', 'file', 'callback','groups','user_upload','api','$rootScope','Upload','$timeout','$location',
        function($scope,$uibModalInstance, alerts, title, file, callback, groups, user_upload, api, $rootScope, Upload, $timeout, $location)
        {
            $scope.user_upload = user_upload;

            $scope.edit = false;
            if(file != null) {
                $scope.file = file;
                $scope.org_file = {};
                angular.copy(file, $scope.org_file);
                $scope.edit = true;
            }
            else
                $scope.file = {upload_type:"UPLOAD_FILE"};

            $scope.title = title;
            $scope.userFile = null;
            $scope.dismiss = function() { $rootScope.$broadcast('refresh-files'); $uibModalInstance.dismiss(); };
            $scope.groups = groups;
            $scope.viewFile = function()
            {
                var path;

                switch($scope.org_file.upload_type)
                {
                    case "LINK":
                        path = $scope.org_file.href;
                        break;
                    case "UPLOAD_FILE":
                        path = $location.$$absUrl.toString().replace($location.$$path, "/" + $scope.org_file.href);
                        break;
                    case "LOCAL_FILE":
                        path = ('bmportal://' + $scope.org_file.id);
                        break;
                }

                window.open(path);
            };


            function validate(file)
            {


                if(null == $scope.file.name || $scope.file.name.length <= 0)
                    return {msg:"Please enter in the name of this file", error:true};
                else if(null == $scope.file.type_code || $scope.file.type_code.length <= 0)
                    return {msg:"Please select a File Group", error:true};
                switch($scope.file.upload_type)
                {
                    case 'LINK':
                        if(null == $scope.file.href || ($scope.file.href.indexOf('http') < 0 && $scope.file.href.indexOf('https') < 0))
                            return {msg:'Improper Link format! Please enter in a Valid Web Link! Example: (http://bettermadesnackfoods.com/)', error:true};
                        break;
                    case 'UPLOAD_FILE':
                        if(null == file)
                            return {msg:"Please select a file to upload!", error:true};
                        break;
                    case 'LOCAL_LINK':

                        break;
                }
                return {error:false};
            }

            $scope.submit = function(file)
            {
                var url;

                if($scope.edit) {
                    url = '/api/file/edit-file';
                    //TODO: Validate File upload on edit!
                    if(validate(file).error && $scope.file.upload_type != "UPLOAD_FILE")
                    {
                        alerts.error('Error', validate(file).msg);
                        return;
                    }
                }
                else
                {
                    console.log(file);

                    url = '/api/file/add-file';
                    if(validate(file).error)
                    {
                        alerts.error('Error', validate(file).msg);
                        return;
                    }
                }

                if(file == null)
                {
                    file = {};
                }
                file.upload = new Upload.upload(
                    {
                        url:url,
                        data: {file_data:$scope.file, file:file}
                    }
                );
                file.upload.then(
                    function(response)
                    {
                        $timeout(function()
                        {
                            alerts.success('Success', "File " + ($scope.file.id != null ? "Updated" : "Uploaded!"));
                            $scope.dismiss();
                        });
                    },
                    function(response)
                    {
                        alerts.error('Error', response.status + ': ' + response.data.error);
                    },
                    function(evt)
                    {
                        file.progress = Math.min(100,parseInt(100.0 * evt.loaded / evt.total));
                    }
                );


            };
            $scope.removeFile = function(id)
            {
                alerts.confirm('Are you Sure?', 'Are you sure you want to remove the file?', function()
                {
                    var id = $scope.file.id != null ? $scope.file.id : id;
                    api.removeFile(id)
                        .then(function(result)
                        {
                            alerts.success('Success','File has been removed!');
                            $scope.dismiss();
                        })
                        .catch(function(error)
                        {
                            alerts.error('Error', error);
                        });
                }, null);
            };
            $scope.approveFile = function()
            {
                alerts.confirm('Are you Sure?', 'This will add the file to the selected group, continue?', function()
                {
                   api.approveFile($scope.file.id)
                       .then(function(res)
                       {
                           alerts.success('Success', 'File Approved!');
                           $scope.file.approved = 1;
                           $rootScope.$broadcast('refresh-files');
                       })
                       .catch(function(error)
                       {
                           alerts.error('Error', error);
                       });
                }, null);

            };

        }
    ]);
})(window.bettermade_portal);